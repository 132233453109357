<template>
    <div class="table-responsive pos-loc-table" id="works-grid-table">
        <p>{{ this.taskStatusSum }}</p>
        <ejs-treegrid
            ref='locationPositionGrid'
            class="location-position-grid"
            :dataSource='tasksGridData.rows'
            childMapping='children'
            :treeColumnIndex='1'
            :dataBound='dataBound'
            :enableCollapseAll='false'
            :actionComplete='actionCompleteEvent'
            :enableStickyHeader="true"



            >
            <e-columns>
                <e-column field='id' headerText='id' isPrimaryKey='true' :allowEditing='false' width='100' :visible='false'></e-column>
                <e-column field='name' headerText='Naziv' width='188' textAlign='Left'></e-column>
                <!-- <e-column field='positions' headerText='Pozicije' :template="editMode?workLocationsPositionsTemplate():undefined" width='100' textAlign='Left'></e-column> -->
                <e-column v-for="workPosition, workPositionIndex in tasksGridData.columns" :key="workPositionIndex" v :field="'tasks.' + workPositionIndex" width='177' 
                    :template="gridBasicTaskTemplate(workPositionIndex)"
                    :headerText='taskHeaderAccessor(workPosition)'  
                    :disableHtmlEncode='false'
                    :valueAccessor='taskAccessor'
                    ></e-column>
                

            </e-columns>
        </ejs-treegrid>
    </div>
</template>

<script>
import UtilService from '@/service/UtilService';
import {mapState} from 'vuex'
import { Filter, Edit, Sort, Reorder, Resize, RowDD, Toolbar, Page, ColumnMenu, CommandColumn } from "@syncfusion/ej2-vue-treegrid";    
import GridBasicTaskTemplate from '@/components/grid-templates/tasks/GridBasicTaskTemplate.vue';

// import WorkPositionGroupButtons from '../work-positions/WorkPositionGroupButtons.vue';


export default {
    components: { },

    provide: {
        treegrid: [ Filter, Edit, Sort, Reorder, Resize, RowDD, Toolbar, Page, ColumnMenu, CommandColumn ]
    },

    watch: {
    },

    props: {
        tasksGridData: {
            type: Object,
            default: () => {return {columns: [], rows: []}},
        },
        taskStatusSum: {
            type: Array,
            default: new Array(),
        },

        editMode: {
            type: Boolean,
            default: false
        },

        expandedView: {
            type: Boolean,
            default: false
        },

    },

    data () {
        return {
            initialLoadGrid: true,
            workLocationsList: [],
            workPositionsList: [],

            selectedTasks: [], 

            selectedSumFilter: "CREATED",

            gridBasicTaskTemplate(taskId) {
                let propsData = {
                    taskId: taskId,
                    taskFilter: this.taskStatusSum,
                    parent: this,
                    mode: "VIEW",
                }

                if (this.editMode) {
                    propsData.mode = "SELECT"
                }
                
                return () => {
                    return {
                        template: {
                            extends: GridBasicTaskTemplate,
                            propsData: propsData,

                        }
                    }
                }
                
            },
        }

        
    },

    computed: {
        ...mapState([
        'position_units',
        ]),

        workOrderId() {
            return Number(this.$route.params.woId);
        },

        totalRows() {
            return this.tasksGridData?.rows?.length
        }

    },

    methods: {
        
        taskAccessor(field, data) {            
            if (field == "key") return data.key;

            let fieldToken = field.split(".")
            
            if (data.children?.length > 0){
                let count = data.tasks_summary?.at(fieldToken[1])[this.selectedSumFilter];
                return count
            }

            return data.tasks?.at(fieldToken[1])?.task_status;
        },

        taskHeaderAccessor(workPosition) {
            return workPosition.name
        },


        actionCompleteEvent(event) {
            if (event.requestType == "refresh") {
                this.onGridRefresh();
            }
        },

        onGridRefresh() {
            if (this.$refs.locationPositionGrid.ej2Instances.flatData.length < 10) {
                this.$refs.locationPositionGrid.ej2Instances.expandAll()
            } else {
                this.$refs.locationPositionGrid.ej2Instances.collapseAll()
            }
        },

        dataBound() {
            // if(this.initialLoadGrid === true) {
            //     if (!this.expandedView) this.$refs.locationPositionGrid.ej2Instances.collapseAll();
            //     this.initialLoadGrid = false;
            // }
        },

        refreshGrid() {
            this.$refs.locationPositionGrid.ej2Instances.refresh();
        },

        addTaskLocationPositionObj(task, locationId, positionIndex) {
            task.work_location_obj = UtilService.recursiveFindElement(
                this.tasksGridData.rows,
                (current) => {current.id === locationId}
                )
            task.work_position_obj = this.tasksGridData.columns.at(positionIndex)
            return task
        },

        openHistoryModal(task, locationId, positionIndex) {
            task = this.addTaskLocationPositionObj(task, locationId, positionIndex)

            this.$emit("openHistoryModal", task)
        },

        updateSelectedTask(task, locationId, positionIndex) {
            task = this.addTaskLocationPositionObj(task, locationId, positionIndex)

            if (task.selected) {
                this.selectedTasks.push(task)
            } else {
                let index = this.selectedTasks.findIndex(item => item.id === task.id)
                if (index >= 0) this.selectedTasks.splice(index, 1)
            }

            this.$emit("update:selectedTasks", this.selectedTasks);
        },

        /////


        commandClick(event) {
            console.log(event);
        },




    },

    mounted() {
        // UtilService.activateDragMove('works-grid-table')
    }

}
</script>

<style scoped>

.pos-loc-table {
    height: 100vh;
}

.pos-loc-table .row-location {
    background:rgb(230, 230, 230);
}

.pos-loc-table .row-location>td {
    padding-top: 0;
    padding-bottom: 0;
}

/* .pos-loc-table .btn-work-status {
    width: 200px;
} */

.pos-loc-table .selected-location {
    font-weight: bold;
}

.pos-loc-table .workable {
    font-weight: bold;
    max-width: 300px;
    display: block;
    overflow: auto;
}

.pos-loc-table .work-info {
    min-width: 120px;
}

.pos-loc-table .sub-group-pos {
    font-size: 0.8em;
    max-width: 100px;
    display: block;
    overflow: auto;
}

.table > thead > tr {
    position: sticky;
    top: 0;
    background: #fff;
    z-index: 10;
}

.table > tbody .sticky-col {
    position: sticky;
    left: 0;
    background: #fff;
    /* min-width: 200px; */
    border-right: 1px solid rgb(218, 218, 218);
    z-index: 5;
}

.table > thead > tr > th:first-child {
    position: sticky;
    top: 0;
    left: 0;
    background: white;
    z-index: 20;
}

.table > tbody > tr > .sticky-col::after {
    content: '';
    position: absolute;
    background: #dee2e6;
    right: 0;
    top: 0;
    bottom: 0;
    width: 1px;
}

.bg-green-yellow-strip {
    background: repeating-linear-gradient(
    45deg,
    #19dc81,
    #19dc81 10px,
    #b88a01 10px,
    #b88a01 20px
    );
}

.bg-red-yellow-strip {
    background: repeating-linear-gradient(
    45deg,
    #ff7b7b,
    #ff7b7b 10px,
    #b88a01 10px,
    #b88a01 20px
    );
}

.work-info > .work-ready {
    background: yellow !important;
}
.work-info > .approved-finished {
    background: #00ff00 !important;
}

.location-position-grid .e-grid .e-gridheader { 
    position: -webkit-sticky; 
    position: sticky; 
    box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.142);
    z-index: 1; 
}

</style>