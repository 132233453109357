<template>
    <div>
        <template v-if="hasChildren">
            <p>{{ taskFilter }}</p>
            <h4>{{ taskSum }}</h4>
        </template>
        <template v-else>
            <div v-if="task" class="d-grid work-info">
                <template v-if="mode === 'VIEW'">
                    <button 
                        class="ms-1 btn btn-block btn-light btn-work-status" 
                        :class="getTaskButtonClass()" 
                        :id="'grid-btn-' + task.id" 
                        @click=" loadWorkHistory"
                        >
                        {{ getTaskButtonLabel() }}
                    </button>
                </template>
                <template v-else>
                    <template v-if="task.task_status === 'READY'">
                        <div class="btn-group-grid d-flex">
                            <div class="btn-group grid-select flex-grow-1">
                                <input type="checkbox" class="btn-check" v-model="task.selected" :id="'grid-btn-select-' + task.id" autocomplete="off" @change="selectTask">
                                <label class="btn" :class="getSelectTaskButtonClass()" :for="'grid-btn-select-' + task.id">{{ getTaskButtonLabel() }}
                                    <font-awesome-icon v-if="task.selected" icon="check" />
                                </label>
                            </div>
                            <button 
                                class="btn grid-info btn-outline-info"
                                :id="'grid-btn-' + task.id" 
                                @click=" loadWorkHistory">
                                <font-awesome-icon icon="info" />
                            </button>
                        </div>
                    </template>
                    <template v-else>
                        <button 
                            class="ms-1 btn btn-block btn-light btn-work-status" 
                            :class="getTaskButtonClass()" 
                            :id="'grid-btn-' + task.id" 
                            @click=" loadWorkHistory"
                            >
                            {{ getTaskButtonLabel() }}
                        </button>
                    </template>
                </template>

                <!-- <button v-if="work" class="ms-1 btn btn-block btn-work-status" :id="'grid-btn-' + location.id + '-' + position.id" :class="workClass()" data-bs-toggle="modal" data-bs-target="#modalHistory" @click="loadWorkHistory(location.id, position.id, location.type_id)">{{workLabel()}}</button> -->
                <!-- <button v-else class="ms-1 btn btn-block btn-light btn-work-status" :id="'grid-btn-' + location.id + '-' + position.id" data-bs-toggle="modal" data-bs-target="#modalHistory" v-on:click="loadWorkHistory(location.id, position.id, location.type_id)">/</button> -->
            </div>
        </template>
    </div>
</template>

<script>
export default {
    name: 'grid-username-template',

    data: function() {
        return {
            data: {},
        }
    },

    props: {
        taskId: Number,
        taskFilter: Array,
        parent: Object,
        mode: {
            type: String,
            default: "VIEW"
        }
    },

    computed: {
        hasChildren() {
            if (this.data?.children?.length > 0 ) return true;
            return false;
        },

        taskSum() {
            if (this.hasChildren) {
                let task_summary = this.data.tasks_summary.at(this.taskId)
                let sum = 0
                
                this.taskFilter.forEach(element => {
                    sum += task_summary[element]
                });

                return sum
            }
            return null
        },

        task() {
            if (!this.hasChildren) {
                return this.data.tasks_obj.at(this.taskId)
            }
            return null
        }

    },

    methods: {
        getTaskButtonClass() {
            let task_status = this.data.tasks_obj.at(this.taskId).task_status

            if (task_status == "CREATED") return "task-created"
            if (task_status == "READY") return "task-ready bg-warning"
            if (task_status == "NOT_READY") return "task-not-ready"
            if (task_status == "IN_PROGRESS") return "task-in-progress bg-info"
            if (task_status == "DONE") return "task-done"
        },

        getSelectTaskButtonClass() {
            let task_status = this.data.tasks_obj.at(this.taskId).task_status

            if (task_status == "CREATED") return "task-created btn-outline-secondary"
            if (task_status == "READY") return "task-ready btn-outline-warning"
            if (task_status == "NOT_READY") return "task-not-ready btn-outline-secondary"
            if (task_status == "IN_PROGRESS") return "task-in-progress btn-outline-info"
            if (task_status == "DONE") return "task-done btn-outline-success"
        },

        getTaskButtonLabel() {
            let task_status = this.data.tasks_obj.at(this.taskId).task_status

            if (task_status == "CREATED") return "/"
            if (task_status == "READY") return "Spremno"
            if (task_status == "NOT_READY") return "/!"
            if (task_status == "IN_PROGRESS") return "U toku"
            if (task_status == "DONE") return "Završeno"
        },

        loadWorkHistory(event) {
            event.stopPropagation()
            this.parent.openHistoryModal(this.task, this.data.id, this.taskId)
        },

        selectTask() {
            this.parent.updateSelectedTask(this.task, this.data.id, this.taskId)
        }
    }
}
</script>

<style scoped>
    .btn-group-grid .grid-select label {
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
    }

    .btn-group-grid .grid-info {
        opacity: 0.25;
        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
    }

    .task-done {
        background-color: #2ada88;
    }

</style>
